<template>
  <LayoutMegaMenuSection
    :activeSection="props.activeSection"
    anchor-key="help-and-advice"
    class="help-and-advice-section"
  >
    <LayoutMegaMenuRow>
      <LayoutMegaMenuColumn
        class="bg-stone-white max-w-[17rem] shrink-0"
        sticky
        hide-divider
      >
        <LayoutMegaMenuCategoryAnchor
          v-if="siteSettings.categories.includes('sexual-health')"
          category-anchor="sexual-health-and-stis"
          :active-category-anchor="helpAndAdviceActiveCategoryKey"
          :title="$t('mainmenu.helpAndAdviceMenu.sexualHealth.title')"
          :description="$t('mainmenu.helpAndAdviceMenu.sexualHealth.introText')"
          :on-activated="handleCategoryAnchorActivated"
        />
        <LayoutMegaMenuCategoryAnchor
          v-if="siteSettings.categories.includes('health-and-wellness')"
          category-anchor="health-and-wellness"
          :active-category-anchor="helpAndAdviceActiveCategoryKey"
          :title="$t('mainmenu.helpAndAdviceMenu.healthAndWellness.title')"
          :description="$t('mainmenu.helpAndAdviceMenu.healthAndWellness.introText')"
          :on-activated="handleCategoryAnchorActivated"
          icon="heart-full.svg"
        />
        <LayoutMegaMenuCategoryAnchor
          category-anchor="support-faqs"
          :active-category-anchor="helpAndAdviceActiveCategoryKey"
          :title="$t('mainmenu.helpAndAdviceMenu.supportAndFAQ.title')"
          :description="$t('mainmenu.helpAndAdviceMenu.supportAndFAQ.introText')"
          :on-activated="handleCategoryAnchorActivated"
          icon="support-icon.svg"
        />
      </LayoutMegaMenuColumn>

      <LayoutMegaMenuColumn class="w-screen grow" layout="categories">
        <LayoutMegaMenuCategory
          v-if="siteSettings.categories.includes('sexual-health')"
          :active-key="helpAndAdviceActiveCategoryKey"
          anchor-key="sexual-health-and-stis"
        >
          <template #title>
            <LayoutMegaMenuColumnTitle
              class="flex flex-row gap-2 items-center text-[#213756_!important] font-semibold text-lg"
            >
              {{ $t("mainmenu.helpAndAdviceMenu.sexualHealth.topicTitle") }}
            </LayoutMegaMenuColumnTitle>
          </template>
          <div class="grid grid-cols-3 px-6 mt-6 pb-8">
            <div v-for="(items, index) in itemsObject" :key="index" class="space-y-2">
              <LayoutMegaMenuItem
                v-for="(item, index) in items"
                :title="item.title"
                :href="item.href"
                :key="item.title"
              />
            </div>
          </div>
          <LayoutMegaMenuFooterLink
              class="mt-auto pl-8"
            >
            <NuxtLinkLocale 
              :locale="switchBlogLocale()" 
              to="/help-and-advice/sexual-health-and-stis" 
              class="hover:underline" 
              v-on:click.prevent="handleMenuRedirect"
            >
              {{ $t("mainmenu.helpAndAdviceMenu.sexualHealth.linkTitle") }}
            </NuxtLinkLocale>
          </LayoutMegaMenuFooterLink>
        </LayoutMegaMenuCategory>

        <LayoutMegaMenuCategory
          v-if="siteSettings.categories.includes('health-and-wellness')"
          :active-key="helpAndAdviceActiveCategoryKey"
          anchor-key="health-and-wellness"
        >
          <template #title>
            <LayoutMegaMenuColumnTitle
              class="flex flex-row gap-2 items-center text-[#213756_!important] font-semibold text-lg"
            >
              {{ $t("mainmenu.helpAndAdviceMenu.healthAndWellness.topicTitle") }}
            </LayoutMegaMenuColumnTitle>
          </template>
          <div class="grid grid-cols-2 px-6 mt-6 pb-8">
            <div v-for="(submenu, index) in helpHealthSubMenu" :key="index" class="space-y-2">
              <LayoutMegaMenuItem
                v-for="(item, index) in submenu"
                :key="index"
                :title="item.title"
                :href="item.href"
              />
            </div>
          </div>
          <LayoutMegaMenuFooterLink
              class="mt-auto pl-8"
            >
            <NuxtLinkLocale 
              :locale="switchBlogLocale()" 
              to="/help-and-advice/health-and-wellness" 
              class="hover:underline" 
              v-on:click.prevent="handleMenuRedirect"
            >
              {{ $t("mainmenu.helpAndAdviceMenu.healthAndWellness.linkTitle") }}
            </NuxtLinkLocale>
          </LayoutMegaMenuFooterLink>
        </LayoutMegaMenuCategory>

        <LayoutMegaMenuCategory
          :active-key="helpAndAdviceActiveCategoryKey"
          anchor-key="support-faqs"
        >
          <div
            class="grid md:grid-cols-1 lg:grid-cols-2 h-full px-[1.75rem] pt-2"
          >
            <div>
              <p
                class="flex text-[#213756_!important] font-semibold text-[13px] uppercase mb-6"
              >
                {{ $t("mainmenu.helpAndAdviceMenu.supportAndFAQ.contactUs") }}
              </p>
              <LayoutMegaMenuItem
                class="contact-items"
                v-for="item of listOfitems"
                :key="item.title"
                v-bind="item"
                @click="item.name === 'liveChat' && useShowChatWidget()"
              />
            </div>
            <div>
              <p
                class="flex text-[#213756_!important] font-semibold text-[13px] uppercase mb-6"
              >
                {{ $t("mainmenu.helpAndAdviceMenu.supportAndFAQ.faq") }}
              </p>
              <LayoutMegaMenuItem
                v-for="item in faqTopics"
                :title="item.title"
                :href="item.href"
                :key="item.title"
              />
            </div>
          </div>
          <LayoutMegaMenuFooterLink
              class="mt-auto pl-8"
            >
            <NuxtLinkLocale 
              :locale="switchBlogLocale()" 
              to="/faqs" 
              class="hover:underline" 
              v-on:click.prevent="handleMenuRedirect"
            >
              {{ $t("mainmenu.helpAndAdviceMenu.supportAndFAQ.faqLink") }}
            </NuxtLinkLocale>
          </LayoutMegaMenuFooterLink>
        </LayoutMegaMenuCategory>
      </LayoutMegaMenuColumn>
      
      <LayoutMegaMenuColumn
        class="max-w-[16rem] shrink-0 hidden xl:flex"
        sticky
        hide-divider
      >
        <div 
          class="megamenu-sidebar"
          :class="{ 
            'hide-block': helpAndAdviceActiveCategoryKey == 'support-faqs',
          }"
        >
          <LayoutMegaMenuContactUs />
        </div>
        <div 
          class="megamenu-sidebar"
          :class="{ 
            'hide-block': helpAndAdviceActiveCategoryKey != 'support-faqs',
          }"
        >
        <ClientOnly>
          <LayoutMegaMenuContactUs /> 
        </ClientOnly>
        </div>
      </LayoutMegaMenuColumn>
    </LayoutMegaMenuRow>
  </LayoutMegaMenuSection>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/global";
import { useShowChatWidget } from "~~/utils/useTawkMessenger";
import type {
MegaMenuAnchorKey,
MegaMenuCategoryAnchorKey,
} from "../index.vue";
const { t } = useI18n();

interface SiteSettings {
  categories: string[];
}

const globalStore = useGlobalStore();
const siteSettings = computed(() => (globalStore.siteSettings ?? []) as SiteSettings);

interface MegaMenuSection {
  activeSection?: MegaMenuAnchorKey;
}

const props = withDefaults(defineProps<MegaMenuSection>(), {});

const helpAndAdviceActiveCategoryKey = ref<
  MegaMenuCategoryAnchorKey<"help-and-advice">
>("sexual-health-and-stis");
const handleCategoryAnchorActivated = (
  categoryAnchor: MegaMenuCategoryAnchorKey<"help-and-advice">
) => {
  helpAndAdviceActiveCategoryKey.value = categoryAnchor;
};

// Generic content and api fetching
const listOfitems: Array<{
  title: string;
  href: string;
  icon: string;
  name?: string;
}> = [
  {
    title: "mainmenu.helpAndAdviceMenu.supportAndFAQ.contactMenu.call247",
    href: "call",
    icon: "phone.svg",
  },
  {
    title: t("mainmenu.helpAndAdviceMenu.supportAndFAQ.contactMenu.livechat"),
    href: "",
    icon: "chat-bubble.svg",
    name: "liveChat",
  },
  {
    title: t("mainmenu.helpAndAdviceMenu.supportAndFAQ.contactMenu.contactPage"),
    href: "/contact-us",
    icon: "envelope.svg",
  },
];

const faqTopics = globalStore.siteFaqTopicMenu.slice(1);

const itemsObject = computed(() => {
  const sitehelpMenu = globalStore.sitehelpMenu.slice(1);
  const menuLength = sitehelpMenu.length;
  const columnCount = 3;
  const columnLength = Math.ceil(menuLength / columnCount);
  const result = [];

  for (let i = 0; i < columnCount; i++) {
    result.push(sitehelpMenu.slice(i * columnLength, (i + 1) * columnLength));
  }

  return result;
});

const formattedSubCategories = ref({});
if (siteSettings.value.categories.includes('health-and-wellness')) {
  formattedSubCategories.value = globalStore.sitehelpHealthSubMenu;
}

const helpHealthSubMenu = computed(() => {
  const midpoint = Math.ceil(formattedSubCategories.value.length / 2);
  const result = [formattedSubCategories.value.slice(0, midpoint), formattedSubCategories.value.slice(midpoint)];
  return result;
});
</script>

<style scoped lang="scss">
.megamenu-sidebar {
  @apply duration-500 h-auto my-0;
  transition: opacity 0.5s, height 0.5s, margin-top 0.5s, margin-bottom 0.5s;

  &.hide-block {
    @apply absolute opacity-0 h-0 pointer-events-none;
  }
}
</style>
